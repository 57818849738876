<template>
  <div>
     <div class="Form--Boxed mb-8"> 
        <div class="flex items-center justify-between mb-4">
          <h4 class="text-xl mb-4 ">{{t('actions.add')}} {{t(`global.${type}.p`)}}  </h4>
          <button @click="removeAll" class="Btn Btn--Delete">{{t('actions.remove_all_courses')}}</button>
        </div>
        <div class="flex flex-1 mb-4">
          <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
          <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
        </div>
        <div v-if="loading" class="LoadingBox mt-4 text-center">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <div v-else-if="error != ''" class="Box Box--Clear">
        <p class="text-danger">{{error}}</p>
      </div>
      <div v-else>
        <div v-if="filteredCourses.length > 0">
          <ul class="List--Lined List--Lined--Thin">
            <li v-for="course in pageOfItems" :key="course.id" class="flex items-center justify-between">
              <span>{{course.name}}</span>
              <button class="flex items-center" v-if="!course.included"  @click="addCourse(course)">
                <span class="mr-2 text-warning cursor-pointer">Non presente</span>
                <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
              </button>
              <button v-else class="flex items-center" @click="removeCourse(course)">
                <span class="mr-2 text-success cursor-pointer">Presente</span>
                <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                  <svg height="16" width="16" class="fill-current"> 
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                </span>
              </button>
            </li>
          </ul>
          <div class="text-right mt-2">
            <button :disabled="saveDisabled" class="Btn Btn--Save" @click="openModal()">
              {{t('actions.save_changes')}} {{saveDisabled ? '' : `(${addedCourses.length+removedCourses.length} corsi)`}}
            </button>
          </div>
          <div class="text-center mt-2">
            <jw-pagination  :items="filteredCourses" @changePage="onChangePage" :labels="customLabels" class="PaginationList"></jw-pagination>
          </div>
        </div>
        <div v-else class="mt-4 text-center">
          <p>{{t('pages.courses.no_courses')}}</p>
        </div>
      </div>
    </div>


    <modal :name="`confirm-modal-${type}`" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.user.editing')}}:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
          <div v-if="addedCourses.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.user.courses_added')}}: ({{ addedCourses.length }})</h4>
            <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="course in addedCourses" :key="course.id">
              {{ course.name}}
              </li>
            </ul>
          </div>
          <div v-if=" removedCourses.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.user.courses_removed')}}: ({{ removedCourses.length }})</h4>
            <ul v-if=" removedCourses.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="course in removedCourses" :key="course.id">
                {{course.name }}
              </li>
            </ul>
          </div>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveCourses">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide(`confirm-modal-${type}`)">{{t('actions.undo')}}</button>
      </div>
    </modal>
    
  </div>
  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api'


export default {

  props: {
    tenant: String,
    type: String
  },

  components: {
    
  },

  data() {
    return {
      academy: {},
      tenantCourses: [],
      lacerbaCourses: [],
      pageOfItems: [],
      pageOfItemsPaths: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      loading: false,
      search: '',
      error: '',
      page: 1,
      addedCourses: [],
      removedCourses: [],
      addedCourses: [],
      removedCourses: [],
      allSelected: false
    }
  },
  
  computed: {
    ...mapState({ 
      //allAcademies: state => state.general.allAcademies
    }),

    filteredCourses() {
      let filteredCourses = []
      filteredCourses =  this.lacerbaCourses.map((el) => {
        return  {...el, included: this.tenantCourses.some(course => (course.external_course_id === el.id) && (course.external_academy_name === 'lacerba-api-fad'))} 
      })
      return filteredCourses
    }


  },
  mounted() {
   this.showAcademy()
   this.getTenantCourses(this.tenant)
   this.getLacerbaCourses('lacerba-api-fad')
  },
  
  methods: {

     ...mapActions({
      //getAllAcademies: 'getAllAcademies'
    }),

    getTenantCourses() {
      api.General.getCourses({ 
        tenant: this.tenant,
        type: this.type 
      }).then(res => {
        this.tenantCourses = res.courses
      }).catch( err => {
        this.error = err.message
      })
    },

    getLacerbaCourses() {
      api.General.getCourses({ 
        tenant:'lacerba-api-fad',
        search: this.search,
        type: this.type
      }).then(res => {
        this.lacerbaCourses = res.courses
      }).catch( err => {
        this.error = err.message
      })
    },
      
    showAcademy() {
      api.General.showAcademy({
        tenant: this.tenant,
        search: this.search
      }).then(res => {
        this.academy = res
      }).catch( err => {
        this.error = err.message
        this.loading = false
      })
    },


    getCoursesFromSearch() {
      this.getTenantCourses(this.tenant)
      this.getLacerbaCourses('lacerba-api-fad')
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },


    addCourse(course) {
      course.included = true
      
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.removedCourses = this.removedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.addedCourses.push(course)
      }
    },

    removeCourse(course) {
      course.included = false
      // user was not enrolled but added to added user. doesn't have to be removed
      if ( this.addedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.addedCourses = this.addedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.removedCourses.push(course)
      }
      
    },

    removeAll() {
      this.filteredCourses.forEach(el => {
        this.removeCourse(el)
      })
    },

    openModal() {
      this.$modal.show(`confirm-modal-${this.type}`);
    },

    async saveCourses() {

      const addCourses = api.General.addCourses({
        ids: this.addedCourses.map(el => el.id),
        tenant: this.tenant,
        type: this.type
      })
      const removeCourses = api.General.removeCourses({
        ids: this.removedCourses.map(el => el.id),
        tenant: this.tenant,
        type: this.type
      })

      Promise.all([await addCourses, await removeCourses])
        .then( res => {
          this.addedCourses = [];
          this.removedUsers = [];
          this.$modal.hide(`confirm-modal-${this.type}`);
        })
        .catch(err => {
          console.log(err)
        })
    }

  }
}
</script>