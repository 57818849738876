<template>
  <div class="Form--Boxed mb-4">

    <editQuiz :quiz="quiz"  />

    <div class="EditQuiz__NewQuestion">
      <div class="field">
        <label>{{ t('pages.course.add_new_question') }}</label>

          <vue-editor 
            v-model="newQuestion.body" 
            :editorToolbar="customToolbar"
            class="mb-4"
          >
          </vue-editor>

          <button @click="createQuestion" class="Btn Btn--Save">
            {{t('actions.save')}}
          </button>

        <!-- <div class="flex">
          <input v-model="newQuestion.body" v-on:keyup.enter="createQuestion"  type="text" class="mr-4"/>

          <button @click="createQuestion" class="Btn Btn--Save Btn--Icon">
           <span class="text-xl leading-none">+</span>
          </button>
        </div> -->
      </div>
      <questionsList ref="questionsList" :quiz="quiz" />
    </div>

  </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions } from 'vuex'
import editQuiz from '../quiz/editQuiz'
import questionsList from '../quiz/questionsList'
import { VueEditor } from "vue2-editor";


export default {
  props: {
    quiz: Object
  },
  data() {
    return {
      newQuestion: {
        body: ""
      },
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]]
    }
  },
  components: {
    questionsList,
    editQuiz,
    VueEditor
  },
  methods: {

    ...mapActions({
      addQuestion: 'addQuestion'
    }),
    
    createQuestion(){
      if (this.newQuestion.body != "") {
        let self = this
        api.Quiz.createQuestion(this.quiz, this.newQuestion)
        .then((res) => {
          this.addQuestion(res);
          this.newQuestion.body = "";
          this.$refs.questionsList.activateInput(res);
        })
        .catch(e =>{
         
        })
      }
    }
  }
}
</script>