<template>
  <div class="mb-6">
    <h4 class="text-xl mb-4 flex justify-between">
      {{t('pages.course.edit_quiz')}} 
      <button @click="removeQuiz" class="Btn Btn--Delete Btn--Icon">
          <svg height="16" width="16" class="fill-current"> 
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
    </h4>
    <div class="grid grid-cols-3 gap-4">
      <div class="field flex items-center field--checkbox">
        <label class="mr-4">{{ t('pages.course.quiz_repeatable') }}</label>
        <input type="checkbox" v-model="quiz.repeatable">  
      </div>
      <div class="field flex items-center field--checkbox">
        <label class="mr-4">{{ t('pages.course.quiz_feedback') }}</label>
        <input type="checkbox" v-model="quiz.feedback">  
      </div>
      <div class="field flex items-center field--checkbox">
        <label class="mr-4">{{ t('pages.course.quiz_go_forward') }}</label>
        <input type="checkbox" v-model="quiz.go_forward">  
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="field   gap-2">
        <label class="">
          {{ t('pages.course.quiz_choose_from_pull') }}
          <button :content="t('pages.course.quiz_choose_from_pull_info')" v-tippy class="Btn--Tooltip ml-2">
            <svg height="18" width="18" class="fill-current"> 
              <use xlink:href="#icon-information-outline"></use>
            </svg>
          </button>
        </label>
        <input type="text" class="" v-model="quiz.choose_from_pull">  
      </div>
      <div class="field gap-2">
        <label >{{ t('pages.course.min_quiz_score') }}</label>
        <input v-model="quiz.min_pass_score"  type="text" class=""/> 
      </div>
    </div>
    <div>
      <button @click="updateQuiz(quiz)" class="Btn Btn--Save">{{t('actions.save')}}</button>
    </div>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    quiz: Object
  },

  methods: {
    updateQuiz(){
      api.Quiz.update(this.quiz)
      .then( res => {
        this.$toastr.s(this.t('actions.saved'));
      }).catch( err => {
        this.$toastr.e(this.t('errors.messages.try_again'));
      } )
    },

    removeQuiz(){
      if(confirm( `Stai per cancellare il quiz \n Cancellandolo, perderai tutti i dati relativi al quiz \n Sei sicuro?` )){
        api.Quiz.destroyQuiz(this.quiz)
          .then( res => {
            location.reload()
         })
      }
    }
  }
}
</script>