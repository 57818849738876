import { pick } from "lodash";
import api from "./instance";

const getEnrollByCourses = (params) =>
  api
    .get(`/admin/stats/enrolls_by_courses`, { params })
    .then((response) => response.data);

const getGeneralInfo = (params) =>
  api
    .get(`/admin/stats/general_info`, { params })
    .then((response) => response.data);

const getGlobalActivities = (params) =>
  api
    .get(`/admin/stats/global_activities`, { params })
    .then((response) => response.data);

const getUserStats = (params) =>
  api
    .get(`/admin/stats/user_stats`, { params })
    .then((response) => response.data);

const getEnrollsByArea = (params) =>
  api
    .get(`/admin/stats/enrolls_by_area`, { params })
    .then((response) => response.data);

const getCli = (params) =>
  api.get(`/admin/stats/cli`, { params }).then((response) => response.data);

export default {
  getEnrollByCourses,
  getGeneralInfo,
  getGlobalActivities,
  getUserStats,
  getEnrollsByArea,
  getCli,
};
