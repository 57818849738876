// Import components
import CoachesIndex from "../components/coaches/index";
import CourseList from "../components/course/index";
import CourseForum from "../components/course/forum";
import LectureShow from "../components/lecture/show";
import CoursePath from "../components/course/path";
import CoursePage from "../components/course/page";
import RegistrationEdit from "../components/registration/edit";
import PieChart from "../components/commons/pieChart";
import UserSettings from "../components/user_settings/index";
import Catalog from "../components/pages/catalog";
import Certifications from "../components/pages/certifications";
import GamificationIndex from "../components/gamification/index";
//admin
import AdminLectures from "../components/admin/lecture/index";
import AdminCourseEdit from "../components/admin/course/edit";
import AdminCourseIndex from "../components/admin/course/index";
import AdminCourseStats from "../components/admin/course/stats";
import AdminCourseShow from "../components/admin/course/show";
import AdminEnrollIndex from "../components/admin/enroll/index";
import AdminEnrollShow from "../components/admin/enroll/show";
import AdminLectureShow from "../components/admin/lecture/show";
import AdminLectureStats from "../components/admin/lecture/stats";
import AdminUserIndex from "../components/admin/user/index";
import AdminUserShow from "../components/admin/user/show";
import AdminAcademySettingIndex from "../components/admin/academy_setting/index";
import AdminPagesIndex from "../components/admin/pages/index";
import AdminPagesEdit from "../components/admin/pages/edit";
import AdminGeneralIndex from "../components/admin/general/index";
import AdminGeneralShow from "../components/admin/general/show";
import AdminStatsGeneral from "../components/admin/stats/general";
import AdminStatsGeneralOld from "../components/admin/stats/generalOld";
import AdminCustomNotificationEdit from "../components/admin/custom_notification/edit";
import AdminPrizeEdit from "../components/admin/prize/edit";
import AdminGroupEdit from "../components/admin/group/edit";
import AdminUserLectures from "../components/admin/lecture/userLectures";
import AdminUserCourses from "../components/admin/lecture/userCourses";

export const CoachesListInstance = {
  el: ".vue-coaches-index",
  component: CoachesIndex,
};

export const CatalogInstance = {
  el: ".vue-pages-catalog",
  component: Catalog,
};

export const CourseListInstance = {
  el: ".vue-courses",
  component: CourseList,
};

export const CourseForumInstance = {
  el: ".vue-course-forum",
  component: CourseForum,
};

export const LectureShowInstance = {
  el: ".vue-lecture",
  component: LectureShow,
};

export const CoursePathInstance = {
  el: ".vue-path",
  component: CoursePath,
};

export const CoursePageInstance = {
  el: ".vue-course-page",
  component: CoursePage,
};

export const RegistrationEditInstance = {
  el: ".vue-registration-edit",
  component: RegistrationEdit,
};

export const PieChartInstance = {
  el: ".vue-piechart",
  component: PieChart,
};

export const UserSettingsInstance = {
  el: ".vue-user-settings",
  component: UserSettings,
};

export const GamificationInstance = {
  el: ".vue-gamification",
  component: GamificationIndex,
};

// admin
export const AdminLecturesInstance = {
  el: ".vue-admin-lectures",
  component: AdminLectures,
};

export const AdminLectureShowInstance = {
  el: ".vue-admin-lecture",
  component: AdminLectureShow,
};

export const AdminLectureStatsInstance = {
  el: ".vue-admin-lecture-stats",
  component: AdminLectureStats,
};

export const AdminCourseEditInstance = {
  el: ".vue-admin-course-edit",
  component: AdminCourseEdit,
};

export const AdminCourseIndexInstance = {
  el: ".vue-admin-course-index",
  component: AdminCourseIndex,
};

export const AdminCourseStatsInstance = {
  el: ".vue-admin-course-stats",
  component: AdminCourseStats,
};

export const AdminCourseShowInstance = {
  el: ".vue-admin-course-show",
  component: AdminCourseShow,
};

export const AdminEnrollIndexInstance = {
  el: ".vue-admin-enroll-index",
  component: AdminEnrollIndex,
};

export const AdminEnrollShowInstance = {
  el: ".vue-admin-enroll-show",
  component: AdminEnrollShow,
};

export const AdminUserIndexInstance = {
  el: ".vue-admin-user-index",
  component: AdminUserIndex,
};

export const AdminUserShowInstance = {
  el: ".vue-admin-user-show",
  component: AdminUserShow,
};

export const AdminAcademySettingIndexInstance = {
  el: ".vue-admin-academy-settings-index",
  component: AdminAcademySettingIndex,
};

export const AdminPagesIndexInstance = {
  el: ".vue-admin-pages-index",
  component: AdminPagesIndex,
};

export const AdminPagesEditInstance = {
  el: ".vue-admin-pages-edit",
  component: AdminPagesEdit,
};

export const AdminGeneralIndexInstance = {
  el: ".vue-admin-general-index",
  component: AdminGeneralIndex,
};

export const AdminGeneralShowInstance = {
  el: ".vue-admin-general-show",
  component: AdminGeneralShow,
};

export const AdminStatsGeneralInstanceOld = {
  el: ".vue-admin-stats-general-old",
  component: AdminStatsGeneralOld,
};

export const AdminStatsGeneralInstance = {
  el: ".vue-admin-stats-general",
  component: AdminStatsGeneral,
};

export const AdminCustomNotificationEditInstance = {
  el: ".vue-admin-custom-notification-edit",
  component: AdminCustomNotificationEdit,
};

export const AdminPrizeEditInstance = {
  el: ".vue-admin-prize-edit",
  component: AdminPrizeEdit,
};

export const AdminGroupEditInstance = {
  el: ".vue-admin-group-edit",
  component: AdminGroupEdit,
};

export const CertificationsInstance = {
  el: ".vue-pages-certifications",
  component: Certifications,
};

export const AdminUserLecturesInstance = {
  el: ".vue-admin-user-lectures",
  component: AdminUserLectures,
};

export const AdminUserCoursesInstance = {
  el: ".vue-admin-user-courses",
  component: AdminUserCourses,
};
