<template>
<div>
  <div :class="['Form--Boxed mb-4', {'unlocked': !settings.ext_script.available_ext_scripts}]" v-if="settings.ext_script">
    <label for="scripts">{{t('settings.external_scripts')}}
      <span v-if="!settings.ext_script.available_ext_scripts" :content="t('global.unlock')" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
          <svg height="18" width="18" class="fill-current text-warning"> 
            <use xlink:href="#icon-lock-closed"></use>
          </svg>
        </span>
    </label>
    <textarea :disabled="!settings.ext_script.available_ext_scripts" id="scripts" v-model="settings.ext_script.value" placeholder="Es: <script> ...... </script>"/>
    <button :disabled="!settings.ext_script.available_ext_scripts" @click="saveSetting(settings.ext_script, 'ext_script')" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
  </div>

  <div class="Form--Boxed mb-4" v-if="settings.lang">
    <div class="field">
      <label for="lang">{{t('global.language')}}</label>
      <select name="lang" id="lang" v-model="settings.lang.value" class="Select Select--Half language-select">
        <option v-for="language in languages" :key="language.id" :value="language.code">
          {{ language.code }}
        </option>
      </select>
    </div>
    <button @click="saveSetting(settings.lang, 'lang', true)" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
  </div>

  <div class="Form--Boxed Form--MaxWidth mb-4">
    <label for="">{{t('settings.show_catalog')}}</label>
    <div class="flex items-start field--checkbox mb-4">
      <input type="checkbox" v-model="show_catalog" id="show_catalog" class="mt-2">  
      <label class="ml-2 normal" for="show_catalog"> {{t('settings.show_catalog_hint')}}</label>
    </div>
    <button @click="saveFeature({name: 'show_catalog', value: show_catalog})" class="Btn Btn--Save mb-4">{{t('actions.save')}}</button>
  </div>

  <div class="Form--Boxed" v-if="settings.block_forward">
    <div  class="field flex items-start field--checkbox">
      <input type="checkbox" v-on:change="changed = true" v-model="blockForwardValue" id="skip-lectures" class="mt-2" >  
      <label class="ml-2" for="skip-lectures">{{t('pages.users.block_forward')}}</label>
    </div>
    <div class="border-l-4 border-warning text-warning text-orange-700 px-4 py-1 mb-3" role="alert">
      <p class="font-bold">{{t('settings.apply.text1')}}</p>
      <p>{{t('settings.apply.text2')}}</p>
      <div class="field--inline flex items-center gap-3">
        <div class="flex items-center gap-1">
          <input type="radio" id="one" value="true" v-model="inherit"  />
          <label for="one" class="mb-0">{{ t('global.positive') }}</label>
        </div>
        <div class="flex items-center gap-1">
          <input type="radio" id="two" value="false" v-model="inherit"  />
            <label for="two" class="mb-0">{{ t('global.negative') }}</label>
        </div>
      </div>
      <p class="text-danger" v-if="selectOne && inherit === ''">{{t('settings.apply.text3')}}</p>
    </div>
     <button  class="Btn Btn--Save mb-4" @click="confirmApply(
      {id: settings.block_forward.id, name: settings.block_forward.name, value: blockForwardValue },
      'block_forward'
     )">{{t('actions.save')}}</button>
  </div>
</div>

  
</template>

<script>

import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import { global as EventBus } from '../../../js/utils/eventBus';

export default {
  name: 'Settings',
  props: {
   settings: Object,
   features: Array
  },
  data() {
    return {
      inherit: "",
      blockForwardValue: false,
      changed: false,
      selectOne: false,
      fontCustomValue: (this.settings && this.settings.font_custom) ? this.settings.font_custom.value || "" : "",
      languages: [],
      show_catalog: false,
    }
  },
  mounted() {
    this.fetchLanguages();
    this.show_catalog = this.showCatalog === 'true'
    setTimeout(() => {
      this.blockForwardValue = ['t', 'true', true].includes(this.settings.block_forward.value);

    }, 100);
  },

  computed: {
    showCatalog(){
      return this.features.find(x => x.name === 'show_catalog')?.value
    },
  },
 
  methods: {
    fetchLanguages() {
      api.Language.getLanguagesApi().then(data => {
        console.log("Lingue ricevute:", data);
        this.languages = data;
      }).catch(error => {
        console.error("Errore nel recupero dei codici delle lingue:", error);
      });
    },
    saveSetting(setting, settingType, reload, apply) {
      this.$emit('saveSet', '', setting, settingType, reload, apply);
    },
    async saveFeature(setting) {
      try {
        const res = await api.Feature.create(setting)
        location.reload()
      } catch (error) {
        console.error(error)
      }
     
    },
    confirmApply(setting) {
      if (this.inherit === "") {
        this.selectOne = true
      } else {
        this.saveSetting(setting, "", false, this.inherit)
      }
    },
  }
}
</script>