import { pick } from "lodash";
import api from "./instance";

const getUserCourses = (params) =>
  api.get(`/courses.json`, { params }).then((response) => response.data);

const getCourses = (params) =>
  api.get(`/admin/courses.json`, { params }).then((response) => response.data);

/**
 * update course
 */
const update = (courseParams) =>
  api
    .put(`/admin/courses/${courseParams.id}`, courseParams)
    .then((response) => response.data);

/**
 * create related
 */
const createRelated = (courseParams) =>
  api
    .post(`/admin/courses/${courseParams.id}/create_related`, courseParams)
    .then((response) => response.data);

/**
 * get Enrolls
 */
const getEnrollStats = (courseParams, page, sort, light) =>
  api
    .get(`/admin/courses/${courseParams.id}/stats.json`, {
      params: { page, sort, light },
    })
    .then((response) => response.data);

/**
 * get Stats
 */
const getCourseStats = (courseParams) =>
  api
    .get(`/admin/courses/${courseParams.id}/course_stats.json`, { params: {} })
    .then((response) => response.data);

/**
 * export Stats
 */
const exportCourseStats = (courseParams) =>
  api
    .get(`/admin/courses/${courseParams.id}/course_stats_export.json`, {
      params: {},
    })
    .then((response) => response.data);

/**
 * search Enrolls
 */
const getStatsFromSearch = (courseParams, page, sort, search) =>
  api
    .get(`/admin/courses/${courseParams.id}/search.json`, {
      params: { page: page, sort: sort, search: search },
    })
    .then((response) => response.data);

/**
 * update row order
 */
const updateRowOrder = (course) =>
  api
    .post(`/admin/courses/update_row_order`, course)
    .then((response) => response.data);

/**
 * delete related
 */
const destroyRelated = (courseParams) =>
  api
    .put(`/admin/courses/${courseParams.id}/destroy_related.json`, courseParams)
    .then((response) => response.data);

const destroyImage = (courseParams) =>
  api
    .put(`/admin/courses/${courseParams.course_id}/destroy_image`, courseParams)
    .then((response) => response.data);

/**
 * associate area
 */
const associateArea = (course_id, area_id) =>
  api
    .post(`/admin/course_areas`, { course_id, area_id })
    .then((res) => res.data);

/**
 * destroy course_area
 */
const destroyArea = (id) =>
  api.delete(`/admin/course_areas/${id}`).then((res) => res.data);

/**
 * get course areas
 */

const getCourseAreasApi = (course_id) =>
  api.get(`/admin/courses/${course_id}/areas`).then((res) => res.data);

const duplicate = (course_id) =>
  api
    .post(`/admin/courses/${course_id}/duplicate`)
    .then((response) => response.data);

const generateImage = (params) =>
  api
    .get(`/admin/courses/${params.course_id}/generate_image`, { params })
    .then((response) => response.data);

const uploadImage = (params) =>
  api
    .get(`/admin/courses/${params.course_id}/upload_image`, { params })
    .then((response) => response.data);

const checkJobStatus = (params) =>
  api
    .get(`/admin/courses/${params.course_id}/check_job_status`, { params })
    .then((response) => response.data);

export default {
  update,
  getEnrollStats,
  getCourseStats,
  exportCourseStats,
  getStatsFromSearch,
  createRelated,
  destroyRelated,
  updateRowOrder,
  getCourses,
  destroyImage,
  associateArea,
  getCourseAreasApi,
  destroyArea,
  getUserCourses,
  duplicate,
  generateImage,
  uploadImage,
  checkJobStatus,
};
